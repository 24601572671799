import { computed, Ref } from '@vue/composition-api';
import * as N from '@ax/function-utils/nullable';
import * as R from '@ax/vue-utils/ref';
import { prop } from '@ax/object-utils';
import { constant, constZero, pipe } from '@ax/function-utils';
import {
  GetPolicyResultsDevices,
  runGetPolicyResultsDevicesQuery,
  useGetPolicyResultsDevicesQuery,
} from '@/services/policy-results.service';
import { PolicyResultsDeviceDTO } from '@/models/policy-results';

/**
 * Composition used to simplify getting the policy results state by using the other helper
 * functions in this file.
 *
 * @param zoneId The zone UUID.
 * @param policyUuid The policy UUID.
 * @param query The stringified query params
 * @returns The reactive state for policy results.
 */
export function usePolicyResultsDevices(
  zoneId: Ref<string | undefined>,
  policyUuid: Ref<string | undefined>,
  query: Ref<string | undefined>,
) {
  const getResultsQuery = computed(() => {
    return N.isNonNullable(zoneId.value) &&
      N.isNonNullable(policyUuid.value) &&
      N.isNonNullable(query.value)
      ? new GetPolicyResultsDevices({
          zoneId: zoneId.value,
          policyUuid: policyUuid.value,
          query: query.value,
        })
      : undefined;
  });
  const {
    data: unfilteredData,
    lastUpdated,
    ...remaining
  } = useGetPolicyResultsDevicesQuery(getResultsQuery);

  const data = R.updateWhen_(unfilteredData, N.isNonNullable);

  // const lastUpdated = R.map_(data, N.map(prop('timestamp')));

  const devices = computed(() =>
    pipe(
      data.value,
      N.map(prop('data')),
      N.getOrElse(constant<PolicyResultsDeviceDTO[]>([])),
    ),
  );

  const totalDevices = computed(() =>
    pipe(
      data.value,
      N.map((result) => result.metadata?.total_count),
      N.getOrElse(constZero),
    ),
  );

  function refresh() {
    const currentQuery = getResultsQuery.value;
    if (N.isNonNullable(currentQuery)) {
      runGetPolicyResultsDevicesQuery(currentQuery);
    }
  }

  return {
    ...remaining,
    refresh,
    devices,
    lastUpdated,
    totalDevices,
  };
}
