var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"pa-sm-0 pb-lg-0 pb-md-0 pb-sm-6 mt-2",attrs:{"xl":"2","lg":"4","md":"4","sm":"12","order-md":"2"}},[_c('policy-run-result-summary',{attrs:{"hidden-segments":_vm.hiddenSegments}})],1),_c('v-col',{staticClass:"pa-sm-0 pr-lg-6 pr-md-6",attrs:{"xl":"10","lg":"8","md":"8","sm":"12","order-md":"1"}},[_c('ax-table',{attrs:{"data-test-id":"policy-run-result-table","column-select":false,"headers":_vm.headerConfig,"options":_vm.tableState,"item-key":"device_uuid","items":_vm.devices,"server-items-length":_vm.totalDevices},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex mt-2"},[_c('ax-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ax-button',_vm._g({staticClass:"result__results-filter",attrs:{"mode":"secondary","data-test-id":"policy-run-result-filter-button"}},on),[_vm._v(" "+_vm._s(_vm.$t('general.buttons.filter'))+" "),_c('v-icon',{attrs:{"dense":"","right":""}},[_vm._v(_vm._s(_vm.mdiFilter))])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.resultsFilters),function(result){return _c('v-list-item',{key:result,staticClass:"result__list-item",attrs:{"role":"menuitemcheckbox"}},[_c('ax-checkbox',{staticClass:"mb-0",attrs:{"input-value":_vm.queryState.result,"value":result,"label":_vm.$t(("general.statuses." + result)),"hide-details":""},on:{"change":_vm.handleFiltersChanged}})],1)}),1)],1),_c('ax-combobox',{staticClass:"ml-5",attrs:{"label":"Search Device Name","prepend-inner-icon":_vm.mdiMagnify,"clearable":"","hide-details":"","append-icon":null},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1)]},proxy:true},{key:"item.device.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'deviceDetails',
              query: { o: _vm.orgId },
              params: { deviceId: item.device_id },
            }}},[_vm._v(_vm._s(item.device_name))])]}},{key:"item.result",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",class:("result--" + (item.result))},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.resultIcons[item.result] || _vm.mdiHelpBox)+" ")]),_vm._v(" "+_vm._s(_vm.getPolicyResultText(item.result))+" ")],1)]}},{key:"item.timestamp",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(new Date(item.timestamp), _vm.DATE_TIME_ZONE_FORMAT))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('ax-table-row-actions-column',[_c('v-list-item',{on:{"click":function($event){return _vm.runPolicyAndTrack(item.device_id)}}},[_vm._v(" "+_vm._s(_vm.$t('reports.policyResults.actionsMenu.runPolicy'))+" ")]),_c('v-list-item',{on:{"click":function($event){return _vm.rebootOneOrMoreDevicesAndTrack(item.device_id)}}},[_vm._v(" "+_vm._s(_vm.$tc('devices.actions.reboot', 1))+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }